import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fromEvent, interval, throttle } from 'rxjs';

const navs = [{
  path: 'new',
  key: 'new',
  caption: 'New'
}, {
  path: 'domestic-curation',
  key: 'domestic-curatoin',
  caption: '국내추천',
}, {
  path: 'overseas-curation',
  key: 'overseas-curation',
  caption: '해외추천',
}];


function AllTrip() {
  const location = useLocation();
  const navigate = useNavigate();
  const $outlet = useRef();
  const [isOutletScrolledOut, setIsOutletScrolledOut] = useState(false);


  useEffect(() => {
    let subscription;
    if (!!$outlet && !!$outlet.current) {
      subscription = fromEvent(document, 'scroll')
        .pipe(
          throttle(() => interval(5)),
        ).subscribe(() => {
          if ($outlet?.current?.getBoundingClientRect().top < 100) {
            if (!isOutletScrolledOut) setIsOutletScrolledOut(true);
            return;
          }
          if (isOutletScrolledOut) setIsOutletScrolledOut(false);
        });
    }
    return () => subscription?.unsubscribe?.();
  }, [$outlet, isOutletScrolledOut]);

  const {
    siteTitle,
    siteDescription,
  } = useMemo(() => {
    const rootPath = location.pathname.split('/')[2];
    const result = {};
    switch (rootPath) {
      case 'new':
      default:
        result.category = 'NEW';
        result.siteDescripion = '지금 내게 필요한, 혹은 트렌디한 숙소에 대한 정보가 가득한 여행 콘텐츠를 즐겨보세요. 올스테이에서만 확인 가능한 꿀팁도 확인하고, 다음 여행도 계획해 보세요.';
        break;
    }

    return {
      siteTitle: `여행 콘텐츠 모아보기 - ${result.category} | 올스테이`,
      siteDescription: result.siteDescripion || `${result.category}에 대한 정보가 가득한 여행 콘텐츠를 즐겨보세요. &quot;${result.note}&quot; 을 읽고 즐거운 여행을 계획해 보세요.`,
    };
  }, [location.pathname]);

  const siteUrl = `${process.env.REACT_APP__DEEP_HOST}${location.pathname}`;



  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>
        <meta property="og:title" content={siteTitle.replace(' | 올스테이', '')} />
        <meta name="description" content={siteDescription} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:url" content={siteUrl} />
        <link rel="canonical" href={siteUrl} />
        <meta name="twitter:title" content={siteTitle.replace(' | 올스테이', '')} />
        <meta name="twitter:description" content={siteDescription} />
      </Helmet>
      <main id="allstay-main">
        <div className="allstay-contents allstay-contents-box">
          <div className={`flex flex-col gap-2 sticky top-0 bg-[#FFFFFF] z-[102] ${isOutletScrolledOut ? 'drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]' : ''}`}>
            <div className="w-full gap-[10px] pt-[30px] px-[20px]">
              <div className="flex flex-col gap-[30px] max-w-full">
                <div className="relative flex justify-center items-center">
                  <button
                    onClick={() => navigate('/')}
                    className="absolute left-0"
                  >
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 17.409V9.68901L10 3.40901L17 9.68901V17.409H3Z" stroke="black" strokeWidth="1.5" />
                      <path d="M10 12.409L10 17.409" stroke="black" strokeWidth="1.5" />
                    </svg>
                  </button>
                  <h2 className="font-bold text-[15px] leading-[160%] text-[#1E1E1E] justify-self-center">컨텐츠</h2>
                </div>
                <div className="flex justify-between">
                  <div className="w-full flex justify-between items-center gap-[20px] font-bold text-[16px] leading-[140%]">
                    {
                      navs.map(({ path, caption, key }) => (
                        <NavLink
                          key={`alltrip-nav-${key}`}
                          to={path}
                          replace={true}
                          className={({ isActive }) => `text-center w-full h-[32px] ${isActive ? 'border-b-[2px] border-b-[#FF3755] text-[#FF3755]' : 'text-[#1E1E1E]'}`}
                        >
                          <span>{caption}</span>
                        </NavLink>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={$outlet}>
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
}

export default AllTrip;
