
const strg = window.sessionStorage;
const key = 'ab-branch';

export function getSessionAbBranch() {
  return strg.getItem(key);
}

export function setSessionAbBranch(v) {
  return strg.setItem(key, v);
}

const initBranchMaker = (pred) => {
  if (typeof pred !== 'function') return () => {};
  return () => {
    const b = getSessionAbBranch();
    if (b) return b;
    setSessionAbBranch(pred());
    return getSessionAbBranch();
  };
};

const branchA50B50 = () => {
  return Math.random() < 0.5 ? 'a' : 'b';
}

export const initA50B50SessionBranch = initBranchMaker(branchA50B50);

function initAbTest() {
  initA50B50SessionBranch();
}

initAbTest();
