import { adjustPromoPrice, simulateAugSep2024Ssf } from "@util/promotion";
import { allstayHttpService } from "./api";

export const getSuggestedKeyword = async word => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/search/suggest?keyword=${word}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getSuggestedKeyword err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

export const getRegionResult = ({
  search_type,
  region_code,
  limit,
  page,
  searchParams,
  theme_id,
}) => {
  const sp = new URLSearchParams(searchParams);
  search_type && sp.set('search_type', search_type);
  region_code && sp.set('region_code', region_code);
  limit && sp.set('limit', limit);
  page && sp.set('page', page);
  theme_id && sp.set('theme_id', theme_id);

  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get('/public/search/hotel', sp, {
      timeout: 0,
      headers: {
        'Cache-Control': 'public, max-age=900'
      },
    })
    .subscribe({
      next: (res) => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data,
          });
          return;
        }
        reject(res);
      },
      error: (err) => {
        console.error('getRegionResult err', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};

export const getHotelResult = ({
  hotelId,
  // userId,
  searchParamStr,
}) => {
  const searchParams = new URLSearchParams(searchParamStr);
  const spStr = searchParams.toString();

  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/hotel/${hotelId}${!!spStr.length ? `?${spStr}`: ''}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          const resolvingData = {
            isOK: true,
            status: res.status,
            data: {
              seo_meta: res.data.seo_meta,
              tap_seo_meta: res.data.tap_seo_meta,
              ...res.data.hotel_info,
            },
          };
          
          // 2024 05, 2024 06 숙세페 강제 시뮬레이트
          const { isEnabled, dummyPromo } = simulateAugSep2024Ssf(searchParams);
          if (isEnabled) {
            const simulMapPred = (l, i) => ({ ...l, promotions: [{...dummyPromo}] });
            resolvingData.data.plan_list = resolvingData.data.plan_list.map(simulMapPred);
            resolvingData.data.primary_plan_list = resolvingData.data.primary_plan_list.map(simulMapPred);
          }

          // 프로모션 할인액, 할인가격 미리 계산
          const planMapPred = plan => ({ ...plan, promotions: plan?.promotions?.map(promo => ({ ...promo, ...adjustPromoPrice(promo, plan?.price) })) });
          resolvingData.data.plan_list = resolvingData.data?.plan_list?.map(planMapPred);
          resolvingData.data.primary_plan_list = resolvingData.data?.primary_plan_list?.map(planMapPred);

          resolve(resolvingData);
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getHotelResult err', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};

export const getNearHotels = ({
  hotelId,
  distance=5000,
  searchParamStr
}) => {
  const searchParams = new URLSearchParams(searchParamStr);
  distance && searchParams.set('distance', distance);
  const spStr = searchParams.toString();
  
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/hotel/${hotelId}/near-hotel?${spStr}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getNearHotels err', err);
        reject(err);
      },
    });
  }

  return new Promise(promiseExecuter);
};

export const getHotelReviews = ({
  hotelId,
  pageNum=1,
  limit=30
}) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/hotel/${hotelId}/review?page=${pageNum}&limit=${limit}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getHotelReviews err', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};

export const getOutlink = ({
  hotelId,
  channel,
  target_url,
  checkin,
  checkout,
  room_info,
  os_version,
  app_version,
  device_model,
  device_id,
}) => {
  const sp = new URLSearchParams();
  target_url && sp.set('target_url', encodeURIComponent(target_url));
  checkin && sp.set('checkin', checkin);
  checkout && sp.set('checkout', checkout);
  room_info && sp.set('room_info', room_info);
  os_version && sp.set('os_version', os_version);
  app_version && sp.set('app_version', app_version);
  device_model && sp.set('device_model', device_model);
  device_id && sp.set('device_id', device_id);
  const spStr = sp.toString();

  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/outlink/${hotelId}/${channel}${!!spStr ? `?${spStr}` : ''}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            status: 200,
            ...res
          });
        }
        reject(res);
      },
      error: err => {
        console.error('getOutlink err', err);
        reject(err);
      }
    })
  };

  return new Promise(promiseExecuter);
};
