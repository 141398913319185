import * as _ from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import decode from '@util/decode';
import { ShareBtn } from "@comp/Share";
import FavoriteButtonDark from '@comp/Interested/FavoriteButtonDark';
import { addItem } from '@store/recentContent';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import { blogPosts } from '@service/blog';
import { NavLink } from 'react-router-dom';
import { makeReachScrollDepthThresholdsStream } from '@stream/instanceScroll';
import { fromEvent, interval, throttle } from 'rxjs';
import { BackButton } from '@comp/BackButton';
import { updateBookmarkId } from '@store/tags';
import { updateStoreBookmarkId } from '@store/blogPosts';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import handleChangeHelmetState from '@util/handleChangeHelmetState';


function BlogPost() {
  const {
    id,
    seo_meta,
    title,
    content,
    image,
    slug,
    bookmark_id,
    date,
    // publisher,
  } = useLoaderData();

  const [isMetaRendered, setIsMetaRendered] = useState(false);
  const $contentContainer = useRef();
  const [contentHTML, setContentHTML] = useState();
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [bookmarkId, setBookmarkId] = useState(bookmark_id);
  const [recommend, setRecommend] = useState([]);
  const setIsReplaced = useState(true)[1];

  useEffect(() => {
    window.scroll(0, 0);
    const {
      subscribe,
      unsubscribe,
    } = makeReachScrollDepthThresholdsStream();
    subscribe((depth) => {
      if (depth === 0) return;
      window.dataLayer.push({
        event: 'alltrip_post__scroll_scroll-depth',
        scroll_depth_percentage: parseInt(depth),
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useLayoutEffect(() => {
    setIsReplaced(true);
    blogPosts().then(posts => {
      if (!_.isEmpty(posts)) {
        _.remove(posts, post => { return post.id === id });
        setRecommend(_.take(posts, 4));
        setIsReplaced(false);
      }
    }).catch(() => setRecommend([]));
  }, [id, setIsReplaced]);

  useEffect(() => {
    if (!isMetaRendered) return;
    const publishedDateTime = new Date(date);
    window.dataLayer.push({
      event: 'alltrip_post__pv_published-at',
      post_name: title,
      published_at: format(publishedDateTime, 'yyyy-MM-dd'),
      published_year: parseInt(format(publishedDateTime, 'yyyy')),
      published_month: parseInt(format(publishedDateTime, 'MM')),
      published_day: parseInt(format(publishedDateTime, 'dd')),
    });
  }, [date, title, isMetaRendered]);

  useLayoutEffect(() => {
    subscribeUserInfo(setUserInfo);
  }, []);

  const $postNameH2 = useRef();
  const [isPostNameH2ScrolledOut, setisPostNameH2ScrolledOut] = useState(false);
  useEffect(() => {
    let subscription;
    if (!!$postNameH2 && !!$postNameH2.current) {
      subscription = fromEvent(document, 'scroll')
        .pipe(
          throttle(() => interval(5)),
        ).subscribe(() => {
          if ($postNameH2?.current?.getBoundingClientRect().top < 61) {
            if (!isPostNameH2ScrolledOut) setisPostNameH2ScrolledOut(true);
            return;
          }
          if (isPostNameH2ScrolledOut) setisPostNameH2ScrolledOut(false);
        });
    }
    return () => subscription?.unsubscribe?.();
  }, [$postNameH2, isPostNameH2ScrolledOut]);

  useLayoutEffect(() => {
    addItem({
      id,
      title: decode(title),
      slug,
      image,
    });
  }, [id, title, slug, image]);

  useLayoutEffect(() => {
    const $_ = document.createElement('div');
    $_.innerHTML = content;

    $_.querySelectorAll('hr:not(.wp-block-separator)')
      .forEach($hr => {
        $hr.setAttribute('style', 'margin-top: 40px; margin-bottom: 100px;')
      });

    $_.querySelectorAll('h2:not(.has-text-align-center)')
      .forEach($h2 => {
        $h2.classList.add('has-text-align-center');
        $h2.innerHTML = `<strong>${$h2.innerText}</strong>`;
      });

    $_.querySelectorAll('h3:not(.has-text-align-center)')
      .forEach($h3 => {
          $h3.classList.add('has-text-align-center');
          $h3.setAttribute('style', 'margin-top: 30px;')
          $h3.innerHTML = `<span style="color:#ee3755;">${$h3.innerText}</span>`;
      });

    // h4 직후의 p 태그 강제 가운데 정렬
    // $_.querySelectorAll('h4:not(.has-text-align-center) + p')
    //   .forEach($p => {
    //     $p.classList.add('has-text-align-center');
    //   });
      
    $_.querySelectorAll('h4:not(.has-text-align-center)')
      .forEach($4 => {
        $4.classList.add('has-text-align-center');
      });
    
    $_.querySelectorAll('img')
      .forEach($img => {
        $img.setAttribute('width', '100%');
        $img.setAttribute('style', 'height: 224px; object-fit: cover; border-radius: 10px;');
      });

    $_.querySelectorAll('p > img')
      .forEach($img => {
        const $div = document.createElement('div');
        $div.classList.add('wp-block-image');
        const $figure = document.createElement('figure');
        $figure.classList.add('aligncenter');
        $figure.classList.add('size-large');
        $div.appendChild($figure);
        $img.parentElement.replaceWith($div);
        $figure.appendChild($img);
      });

    $_.querySelectorAll('iframe')
      .forEach($iframe => $iframe.setAttribute('width', '100%'));

    $_.querySelectorAll('a[href*="allstay.onelink.me"]')
      .forEach($a => {
        const oldHref = $a.getAttribute('href');
        if (!oldHref) return;
        const url = new URL(oldHref);
        if (!/^allstay.onelink.me$/.test(url.hostname)) return;
        const af_web_dp = new URL(url.searchParams.get('af_web_dp'));
        if (!af_web_dp || !af_web_dp.pathname) return;
        $a.setAttribute('href', af_web_dp.pathname);
        $a.removeAttribute('target');
      });

    $_.querySelectorAll('a[rel]')
      .forEach($a => {
        $a.removeAttribute('rel');
      });

    $_.querySelectorAll('a[rel~=noreferrer]')
      .forEach($a => {
        $a.removeAttribute('rel');
      });

    $_.querySelectorAll('a[rel~=noopener]')
      .forEach($a => {
        $a.removeAttribute('rel');
      });

    $_.querySelectorAll('div[id^=attachment_]')
      .forEach($div => {
        $div.removeAttribute('style');
      })

    $_.querySelectorAll('p > a:not(.wp-block-button__link.has-background.wp-element-button).link-button')
      .forEach($a => {
        if (window.sessionStorage.getItem('ab-branch') === 'a' && $a.classList.contains('ab-testee')) {
          const $span = document.createElement('span');
          $span.setAttribute('class', 'ab-tested was-button-link');
          $a.parentElement.replaceWith($span);
        }
        const $div1 = document.createElement('div');
        $div1.className = 'is-horizontal is-content-justification-center is-layout-flex wp-container-42 wp-block-buttons';
        const $div2 = document.createElement('div');
        $div2.className = 'wp-block-button is-style-fill';
        const $spacer1 = document.createElement('div');
        $spacer1.setAttribute('style', 'height: 20px;');
        const $spacer2 = document.createElement('div');
        $spacer2.setAttribute('style', 'height: 80px;');
        $a.className = 'wp-block-button__link has-background wp-element-button';
        $a.setAttribute('style', 'background-color:#ff3756; height: auto; padding: 16px 20px;');
        $a.querySelector('span')?.setAttribute('style', 'color: white; text-alighn: center; line-height: 125%;');
        $div1.appendChild($spacer1);
        $div1.appendChild($div2);
        $div1.appendChild($spacer2);
        $a.parentElement.replaceWith($div1);
        $div2.appendChild($a);
      });

    $_.querySelectorAll('p > a:not(.link-button.wp-block-button__link.has-background.wp-element-button)')
      .forEach($a => {
        if (window.sessionStorage.getItem('ab-branch') === 'b' && $a.classList.contains('ab-testee')) {
          const $span = document.createElement('span');
          $span.setAttribute('class', 'ab-tested was-common-link');
          $span.innerText = $a.innerText;
          $a.replaceWith($span);
        } else {
          $a.setAttribute('style', 'text-decoration: underline; color: #ff3756; font-weight: 600;');
        }
      });
      
    setContentHTML($_.innerHTML);
  }, [$contentContainer, content]);

  return (
    <>
      <Helmet onChangeClientState={(a, b, c) => handleChangeHelmetState(a, b, c, () => setIsMetaRendered(true))}>
        <meta name="robots" content="index,follow" />
        <title>{seo_meta?.title}</title>
        {seo_meta?.description && <meta name="description" content={seo_meta?.description} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:title" content={seo_meta?.title?.replace(' | 숙소가격 비교 올스테이', '')} />
        {seo_meta?.description && <meta property="og:description" content={seo_meta?.description} />}
        <meta property="og:url" content={seo_meta?.url} />
        <meta property="og:image" content={seo_meta?.image} />
        <link rel="canonical" href={seo_meta?.url} />
        <meta name="twitter:title" content={seo_meta?.title?.replace(' | 숙소가격 비교 올스테이', '')} />
        {seo_meta?.description && <meta name="twitter:description" content={seo_meta?.description} />}
        <meta name="twitter:image" content={seo_meta?.image} />
      </Helmet>
      <main id="allstay-main">
        <div className="allstay-contents relative">
          <div className={`flex flex-col gap-2 sticky top-0 bg-[#FFFFFF] z-[102] ${!isPostNameH2ScrolledOut ? '' : 'drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]'}`}>
            <div className={`w-full gap-[10px] px-[20px] ${isPostNameH2ScrolledOut ? 'pt-[20px] pb-[19px]' : 'pt-[32px] pb-[30px]'}`}>
              <div className="flex justify-between items-center gap-[20px] max-w-full">
                <div className="flex gap-[20px] items-center overflow-hidden">
                  <BackButton />
                  {isPostNameH2ScrolledOut && <h2 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E] truncate max-w-[100%]">{decode(title)}</h2>}
                </div>
                <div className="flex gap-[10px] items-center">
                  <FavoriteButtonDark
                    size={20}
                    bookmark_id={bookmarkId}
                    ref_model="content"
                    ref_id={id}
                    userInfo={userInfo}
                    updateDate={bookmark_id => { setBookmarkId(bookmark_id); updateBookmarkId(id, bookmark_id); updateStoreBookmarkId(id, bookmark_id); }}
                    onClick={({ is_loged_in, turning_on_off }) => {
                      // 페이보릿 버튼 클릭 이벤트 트래킹
                      const pathArr = window.location.pathname.split('/');
                      window.dataLayer.push({
                        event: 'alltrip_post__favoriteButton',
                        as_d1: pathArr[1],
                        as_d2: pathArr[2],
                        // as_context: `favoriteButton`,
                        page_title: window.document.title,
                        page_path: window.location.pathname,
                        page_location: window.location.href,
                        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                        post_title: window.document.title,
                        is_loged_in,
                        turning_on_off,
                      });
                    }}
                  />
                  <ShareBtn
                    url={seo_meta.url}
                    image={image}
                    onClick={() => {
                      // 공유하기 팝업 창을 띄우는 공유하기 버튼 클릭 이벤트 트래킹
                      const pathArr = window.location.pathname.split('/');
                      window.dataLayer.push({
                        event: 'alltrip_post__shareButton',
                        as_d1: pathArr[1],
                        as_d2: pathArr[2],
                        // as_context: `shareButton`,
                        page_title: window.document.title,
                        page_path: window.location.pathname,
                        page_location: window.location.href,
                        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                        sharetype: '',
                        sharetype_click: 0,
                        shareButton_click: 1,
                      });
                    }}
                  >
                    {decode(title)}
                  </ShareBtn>
                </div>
              </div>
            </div>
          </div>
          <div className={`px-[20px] pb-[25px] flex flex-col gap-[20px] bg-[#FFFFFF]`}>
            <div className="flex flex-col gap-[6px]">
              <h1 className={`font-bold text-[16px] leading-[140%] text-[#1E1E1E] ${isPostNameH2ScrolledOut ? 'opacity-0' : ''}`} ref={$postNameH2}>{decode(title)}</h1>
            </div>
          </div>
          <div className="flex flex-col gap-10 pb-10">
            <img alt="블로그 메인 이미지" src={typeof image === 'string' ? image : image?.url} />
            <div ref={$contentContainer} className="allstay-post-content" dangerouslySetInnerHTML={{ __html: contentHTML }} />

            {recommend &&
              <div className="flex flex-col px-[20px] gap-[20px]">
                <div className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]"> 최신 컨텐츠 보기 </div>
                <div className={'grid grid-cols-2 gap-x-[15px] gap-y-[20px] text-[13px] leading-[160%]'}>
                  {recommend.map(({ id, title, image, slug }, i) => {
                    return (
                      <NavLink
                        to={`/alltrip/post/${slug}`}
                        key={`section-item-${id || i}`}
                        className="w-full pt-[100%] flex flex-col gap-[10px] overflow-hidden relative rounded-[10px] bg-center bg-no-repeat bg-cover"
                        style={{
                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.4), rgba(0,0,0,.4)), url('${image}')`,
                        }}
                        onClick={() => { window.scroll(0, 0); }}
                      >
                        <div className={`text-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] absolute flex flex-col z-[2] w-[70%] h-[70%]  text-ellipsis`}>
                          <p className={`text-[15px] font-bold leading-[140%] text-[#FFFFFF]`}>{decode(title)}</p>
                        </div>
                      </NavLink>
                    );
                  })}
                </div>
              </div>}
          </div>
        </div>
      </main>
    </>
  );
}

export default BlogPost;
