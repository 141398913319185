import Heading from '@comp/Headings/Heading';
import React from 'react';
import { NavLink } from 'react-router-dom';

function PostThumbnail({
  onClick,
  titleHeadingLevel=3,
  SubFuncUi,
  fullWidth,

  to, // built at service

  // remote data
  title,
  image,
  // bookmark_id,
  // postId, // data.id
  // region_id,
  // title,
  // distance,
  // image,
  // publisher,
  // bookmark_id,
}) {
  const mergedClass = `block ${fullWidth ? 'w-full pt-[100%]' : 'w-72 h-60'} rounded bg-center bg-no-repeat bg-cover overflow-hidden relative`;
  const contentClass = `w-full h-full ${fullWidth ? 'p-8' : 'p-5'} left-0 top-0 absolute flex-col justify-start items-start gap-4 flex`;
  const subUiClass = `w-6 h-6 ${fullWidth ? 'right-8 top-8' : 'right-5 top-5'} absolute`;
  
  return <NavLink
    to={to}
    onClick={onClick}
    className={mergedClass}
      style={image ? {
      backgroundImage: `url('${image}')`,
    } : {}}
  >
    <div className="w-full h-full left-0 top-0 absolute bg-gradient-to-b from-allstay-gray-1e opacity-70" />
    <div className={contentClass}>
      <div className="flex-col justify-start items-start gap-4 flex">
        <Heading
          className="text-wrap break-keep min-w-44 w-[80%] text-white text-base font-semibold leading-normal"
          level={titleHeadingLevel}
        >
          {title}
        </Heading>
        {/* 태그칩 영역
        <div className="flex justify-start items-start gap-1 flex-wrap">
          {tags.map((t) => <Chip>{t}</Chip>)}
        </div> */}
      </div>
    </div>
    {SubFuncUi ? <div className={subUiClass}>
      <SubFuncUi />
    </div>: ''}
  </NavLink>;
}

export default PostThumbnail;
