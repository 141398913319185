import handleChangeHelmetState from '@util/handleChangeHelmetState';
import * as _ from 'lodash';
import React, { useState, useLayoutEffect, useMemo, useEffect, useRef } from 'react';
import { NavLink, useOutletContext, useNavigate, useLoaderData } from 'react-router-dom';
import { PriceRangeInput, RoomInfoInput, ScheduleInput, SortingTypeInput, GradeInput } from '@comp/SearchInputGroup'
import { formatScheduleCaption, getSchedule, setSchedule } from '@stream/schedule';
import { foramtRoomInfoCaption, getRoomInfo, setRoomInfo } from '@stream/roomInfo';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { differenceInDays, format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { delFavorite, registerFavorite } from '@service/favorite';
import { setSearchPanelVisibility } from '@stream/searchPanelVisibility';
import getPricesOfHotels from '@stream/partialPriceList';
import { getSortingType, setSortingType } from '@store/sortingType';
import { getSlider, setSlider } from '@store/priceRange';
import { Checkbox, FormControlLabel, FormGroup, Skeleton } from '@mui/material';
import { getStayGrade, initialGrade, setStayGrade } from '@store/stayGrade';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { fromEvent, interval, throttle } from 'rxjs';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { parameterizeRoomInfo, parameterizeSchedule } from '@stream/searchParams';
import chainPaginationSync from '@stream/searchList';
import { Helmet } from 'react-helmet';
import normalizeCityCode from '@util/normalizeCityCode';
import { getLastRouterHistory } from '@stream/routerHistory';
import { closeResetModal, setModal } from '@store/modal';
import { isOpenPromotion } from '@util/promotion';

import { ReactComponent as PromotionIcon } from '@svg/Icons/Promotion/promotion.svg';
import { BackButton } from '@comp/BackButton';

const formatNum = new Intl.NumberFormat('ko-KR').format;

const filterToggleInactiveClass = 'px-[14px] border border-[#E0E0E0] bg-[#FFFFFF] text-[#1E1E1E] rounded-full';
const filterToggleActiveClass = 'pl-[14px] pr-[2px] bg-[#4A90E2] text-[#FFFFFF] rounded-l-full';

const FavoriteButton = ({ bookmark_id, hotel_id, userInfo }) => {
  const navigate = useNavigate()
  const [bookmarkId, setBookmarkId] = useState(bookmark_id)

  return <button
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!checkIsLogedInUser(userInfo)) {
        setModal({
          isClosable: true,
          isOpened: true,
          template: () => (
            <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
              <div className="flex flex-col w-56 gap-6 text-gray-900-100">
                <h2 className="text-modal-h">로그인</h2>
                <div className="flex flex-col">
                  <p className="text-modal-desc whitespace-normal">지금 바로 올스테이에 로그인하고</p>
                  <p className="text-modal-desc whitespace-normal">마음에 드는 숙소를 찜하세요!</p>
                </div>
              </div>
              <div className="flex w-full justify-around border-t border-gray-100-100">
                <button
                  className="text-modal-btn text-gray-400 h-major-button"
                  onClick={() => {
                    closeResetModal();
                  }}
                >
                  <span className="w-full">닫기</span>
                </button>
                <button
                  className="text-modal-btn text-sky-blue-400 h-major-button"
                  onClick={() => { navigate('/auth'); closeResetModal(); }}>
                  <span className="w-full">로그인하러 가기</span>
                </button>
              </div>
            </div>
          ),
        });
        return
      }
      ((!!bookmarkId) ? delFavorite(bookmarkId) : registerFavorite('hotel', hotel_id)).then(res => {
        setBookmarkId(res.bookmark_id || null)
      });
    }}
  >
    {
      !!bookmarkId ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#FF3755" />
        <mask id="mask0_695_11536" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
          <path d="M13.7498 2.5C12.2998 2.5 10.9082 3.16213 9.99984 4.20845C9.0915 3.16213 7.69984 2.5 6.24984 2.5C3.68317 2.5 1.6665 4.4782 1.6665 6.99591C1.6665 10.0858 4.49984 12.6035 8.7915 16.4292L9.99984 17.5L11.2082 16.421C15.4998 12.6035 18.3332 10.0858 18.3332 6.99591C18.3332 4.4782 16.3165 2.5 13.7498 2.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_695_11536)">
          <rect width="20" height="20" fill="#FF3755" />
        </g>
      </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#CCCED2" />
        <mask id="mask0_695_11561" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="white" />
        </mask>
        <g mask="url(#mask0_695_11561)">
          <rect width="20" height="20" fill="#CCCED2" />
        </g>
      </svg>
    }
    <span className="sr-only">{!!bookmarkId ? '찜 취소 하기' : '찜 하기'}</span>
  </button>;
};


function RegionSearchResult() {

  const seoMeta = useLoaderData();

  const {
    location,
    searchParams,
    // navigate,
    pathParams,
  } = useOutletContext();
  const [sp, setSp] = searchParams;
  const { check_in, check_out, room_info } = useMemo(() => {
    const spInst = new URLSearchParams(sp);
    return {
      check_in: spInst.get('check_in'),
      check_out: spInst.get('check_out'),
      room_info: spInst.get('room_info'),
    };
  }, [sp]);
  const {
    search_type,
    region_code,
    country_code,
    theme_id,
  } = pathParams;

  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [checkInOut, setCheckInOut] = useState(getSchedule());
  const [roomAndGuestInfo, setRoomAndGuestInfo] = useState(getRoomInfo());
  const [title, setTitle] = useState();

  const [isPaging, setIsPaging] = useState(false);
  const [list, setList] = useState([]);
  const [rowCount, setRowCount] = useState(Number.MAX_SAFE_INTEGER);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [numOfPriceLoadedHotels, setNumOfPriceLoadedHotels] = useState(0);
  
  useEffect(() => {
    if (isPaging) return;
    if (rowCount === numOfPriceLoadedHotels && rowCount !== Number.MAX_SAFE_INTEGER && numOfPriceLoadedHotels !== 0) return;
    setIsPaging(true);
    chainPaginationSync(!!theme_id ? 'curation' : search_type, region_code, 100, 1, sp, theme_id,(data) => {
      if (!data) return;
      const {
        city_name,
        hotel_list,
        page_count,
        row_count,
        page,
      } = data;
      if (page > page_count) return;
      setBreadCrumb(city_name || []);
      setRowCount(row_count || 0);

      if (!!theme_id && !title) setTitle(data.title)

      setList(v => [...v, ...(
        Array.isArray(hotel_list) ? hotel_list.map((h, i) => ({
          ...h,
          suggestedOrder: page * (i + 1),
          price: parseFloat(h.min_price) || Infinity,
          min_price: parseFloat(h.min_price),
          currency: 'KRW',
        })) : [])
      ]);

      const nonPricedHotels = hotel_list.filter(h => _.isNil(h.is_cache_data));
      setNumOfPriceLoadedHotels(v => v + hotel_list.length - nonPricedHotels.length);

      getPricesOfHotels({
        hotelChunkSize: 100,
        srcChunkSize: 1,
        hotel_list: nonPricedHotels,
        check_in,
        check_out,
        room_info,
        historyStateAtBegining: getLastRouterHistory(),
        setter: (lowerPrices) => {
          setList(prevList => prevList.map(hotel => {
            const newHotel = { price: Infinity, ...hotel };

            if(!!lowerPrices[newHotel.hotel_id]) {
              newHotel.ota_num = _.keys(_.groupBy(lowerPrices[newHotel.hotel_id], 'source_table_name_key')).length;
            }

            if (!!lowerPrices[newHotel.hotel_id] && (newHotel.price > lowerPrices[newHotel.hotel_id][0]['price'])) {
              newHotel.price = parseFloat(lowerPrices[newHotel.hotel_id][0]['price']);
              newHotel.source_table_name_key = lowerPrices[newHotel.hotel_id][0]['source_table_name_key'];
            };

            if (!!lowerPrices[newHotel.hotel_id] && !newHotel?.promotions) {
              _.forEach(lowerPrices[newHotel.hotel_id], obj => {
                if (_.has(obj, 'promotions') && obj.promotions !== null) {
                  newHotel.promotions = obj.promotions;
                  return false;
                }
              });
            };
            return newHotel;
          }))
        },
        setNumOfPriceLoadedHotels,
      });
    }).catch(err => {
      console.error('숙소리스트 페이지네이션 요청중 에러발생', err);
      setRowCount(0);
      setNumOfPriceLoadedHotels(0);
    });
  }, [search_type, region_code, check_in, check_out, room_info, isPaging, breadCrumb, numOfPriceLoadedHotels, rowCount, sp, title, theme_id, location]);

  useEffect(() => {
    if ((rowCount === 0 && numOfPriceLoadedHotels === 0) || (rowCount === numOfPriceLoadedHotels && rowCount !== Number.MAX_SAFE_INTEGER && numOfPriceLoadedHotels !== 0)) setIsPaging(false);
  }, [rowCount, numOfPriceLoadedHotels]);

  const [top5Cate, setTop5Cate] = useState([]);

  useEffect(() => {
    const top5Cate = Object.entries(
      list.reduce((acc, cur) => {
        const newAcc = { ...acc };
        if (typeof newAcc[cur.hotel_allstay_type.allstay_hotel_type] === 'undefined') {
          newAcc[cur.hotel_allstay_type.allstay_hotel_type] = {
            count: 0,
            name: cur.hotel_allstay_type.name,
          };
        }
        newAcc[cur.hotel_allstay_type.allstay_hotel_type]['count'] += 1;
        return newAcc;
      }, {})
    ).sort(([, a], [, b]) => b.count - a.count)
      .map(([id, rest]) => ({ id, ...rest }))
      .slice(0, 5);
    setTop5Cate(top5Cate.length <= 5 ? top5Cate : [...top5Cate, { id: NaN, name: '기타' }]);
  }, [list]);

  const [isSortTypeOn, setIsSortTypeOn] = useState(false);
  const [sortType, setSortType] = useState('');

  const [isPriceOn, setIsPriceOn] = useState(false);
  const [priceRange, setPriceRange] = useState(null);

  const [isGradeOn, setIsGradeOn] = useState(false);
  const [grades, setGrades] = useState(null);

  const [isCateOn, setIsCateOn] = useState(false);
  const [cateBuffer, setCateBuffer] = useState([true, true, true, true, true, true]);
  const [cate, setCate] = useState(null);

  const [openedFilterName, setOpenedFilterName] = useState('');

  const handleClickCancelSortTypeToggle = () => {
    setIsSortTypeOn(false);
    setSortType('');
    setSortingType('recommend');
  };

  const handleClickCancelPriceToggle = () => {
    setIsPriceOn(false);
    setPriceRange(null);
    setSlider([0, 100]);
  };

  const handleClickCancelGradeToggle = () => {
    setIsGradeOn(false);
    setGrades(null);
    setStayGrade([true, true, true, true, true, true]);
  };

  const handleClickCancelCateToggle = () => {
    setIsCateOn(false);
    setCate(null);
    setCateBuffer([true, true, true, true, true, true].slice(0, top5Cate.length ? top5Cate.length : 6));
  };

  const handleClickAllFilterToggle = () => {
    setOpenedFilterName('all');
  };

  useLayoutEffect(() => {
    subscribeUserInfo(setUserInfo);
  }, []);

  useEffect(() => { window.scroll(0, 0); }, []);

  const [minPrice, maxPrice, priceCurrency] = useMemo(() => {
    const pricings = list.filter(({ price }) => ((typeof price !== 'undefined') && (price !== Infinity)));
    const prices = pricings.map(({ price }) => price);
    return [pricings.length ? Math.min(...prices) : 0, pricings.length ? Math.max(...prices) : Infinity, 'KRW'];
  }, [list]);

  const filteredList = useMemo(() => {
    return list
      .filter(({ city_code }) => !!city_code)
      .filter(h => {
        if (typeof h.price !== 'number' || h.price === Infinity) return false;
        if (!isPriceOn || (numOfPriceLoadedHotels !== list.length)) return true;
        if ((h.price >= (((maxPrice - minPrice) * priceRange[0] / 100 + minPrice)) || 0) && h.price <= (((maxPrice - minPrice) * priceRange[1] / 100 + minPrice) || Infinity)) return true;
        return false;
      }).sort((a, b) => {
        switch (sortType) {
          case 'price-asc':
            return a.price - b.price;
          case 'price-desc':
            return b.price - a.price;
          case 'grade-asc':
            return a.star_rating - b.star_rating;
          case 'grade-desc':
            return b.star_rating - a.star_rating;
          case 'recommend':
          default:
            return a.originalIndex - b.originalIndex;
        }
      }).filter(h => {
        if (!isGradeOn) return true;
        if (grades.map((g, index) => g ? index : -1).filter(g => g !== -1).findIndex(grade => `${grade}` === `${h.star_rating}`) > -1) return true;
        return false;
      }).filter(h => {
        if (!isCateOn) return true;
        if (top5Cate.filter((t, index) => cate[index] && !isNaN(t.id)).findIndex((t) => `${t.id}` === `${h.hotel_allstay_type.allstay_hotel_type}`) > -1) return true;
        else if (isNaN(top5Cate[top5Cate.length - 1].id) && cate[cate.length - 1]) return true;
        return false;
      })
  }, [list, sortType, priceRange, isPriceOn, grades, isGradeOn, isCateOn, cate, top5Cate, maxPrice, minPrice, numOfPriceLoadedHotels]);

  const [
    checkin,
    checkout,
    nights,
  ] = useMemo(() => {
    const {
      startDate,
      endDate,
    } = checkInOut[0];
    return [
      format(startDate, 'yy-MM-dd', { locale: ko }),
      format(endDate, 'yy-MM-dd', { locale: ko }),
      Math.abs(differenceInDays(startDate, endDate)),
    ];
  }, [checkInOut]);

  const [
    room,
    adult,
    minor,
  ] = useMemo(() => [
    roomAndGuestInfo.length,
    roomAndGuestInfo.reduce((acc, cur) => acc + parseInt(cur.adult), 0),
    roomAndGuestInfo.reduce((acc, cur) => acc + cur.minor.length, 0),
  ], [roomAndGuestInfo]);

  const [isScheduleInputOpened, setIsScheduleInputOpened] = useState(false);
  const [isRoomInfoInputOpened, setIsRoomInfoInputOpened] = useState(false);
  const toggleScheduleInputOpened = () => setIsScheduleInputOpened(v => {
    if (v) setSchedule(checkInOut);
    setIsRoomInfoInputOpened(false);
    return !v;
  });
  const toggleRoomInfoInputOpened = () => setIsRoomInfoInputOpened(v => {
    if (v) setRoomInfo(roomAndGuestInfo);
    setIsScheduleInputOpened(false);
    return !v;
  });
  const confirmSchedule = () => {
    setIsScheduleInputOpened(false);
    const newSchedule = getSchedule();
    if (formatScheduleCaption(newSchedule) === formatScheduleCaption(checkInOut)) return;
    setCheckInOut(newSchedule);
    const newRoomInfo = getRoomInfo();
    let _sp = new URLSearchParams();
    _sp = parameterizeSchedule(newSchedule, _sp);
    _sp = parameterizeRoomInfo(newRoomInfo, _sp);
    setSp(_sp);
    const toURL = new URL(window.location.href);
    toURL.searchParams.set('check_in', _sp.get('check_in'));
    toURL.searchParams.set('check_out', _sp.get('check_out'));
    toURL.searchParams.set('room_info', _sp.get('room_info'));
    const to = `${toURL.pathname}${toURL.search}${toURL.hash}`;
    console.log('to', to);
    window.location.assign(to);
  };
  const confirmRoomInfo = () => {
    setIsRoomInfoInputOpened(false);
    const newRoomInfo = getRoomInfo();
    if (foramtRoomInfoCaption(newRoomInfo) === foramtRoomInfoCaption(roomAndGuestInfo)) return;
    setRoomAndGuestInfo(newRoomInfo);
    const newSchedule = getSchedule();
    let _sp = new URLSearchParams();
    _sp = parameterizeSchedule(newSchedule, _sp);
    _sp = parameterizeRoomInfo(newRoomInfo, _sp);
    setSp(_sp);
    const toURL = new URL(window.location.href);
    toURL.searchParams.set('check_in', _sp.get('check_in'));
    toURL.searchParams.set('check_out', _sp.get('check_out'));
    toURL.searchParams.set('room_info', _sp.get('room_info'));
    const to = `${toURL.pathname}${toURL.search}${toURL.hash}`;
    console.log('to', to);
    window.location.assign(to);
  };

  const isInputsClosed = useMemo(() => !isScheduleInputOpened && !isRoomInfoInputOpened, [isScheduleInputOpened, isRoomInfoInputOpened]);


  const $filters = useRef();
  const [isFilterScrolledOut, setIsFilterScrolledOut] = useState(false);
  useEffect(() => {
    let subscription;
    if (!!$filters && !!$filters.current) {
      subscription = fromEvent(document, 'scroll')
        .pipe(
          throttle(() => interval(10)),
        ).subscribe(() => {
          if ($filters?.current?.getBoundingClientRect().top < 90) {
            if (!isFilterScrolledOut) setIsFilterScrolledOut(true);
            return;
          }
          if (isFilterScrolledOut) setIsFilterScrolledOut(false);
        });
    }

    return () => subscription?.unsubscribe?.();
  }, [$filters, isFilterScrolledOut]);

  const narrowMobileMatches = useMediaQuery('(max-width: 380px)');

  const siteUrl = `${process.env.REACT_APP__DEEP_HOST}${location.pathname}`;

  
  let scrolling = useRef(null);
  const [isHide, setIsHide] = useState();

  useEffect(() => {
    if (!location.pathname.includes('/curation')) return;
    
    const handleScroll = () => {
      setIsHide(true);
      if (!!scrolling.current) {
        clearTimeout(scrolling.current);
      }
      scrolling.current = setTimeout(() => {
        scrolling.current = null;
        setIsHide(false);
      }, 100);
    };

    setIsHide(false);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, location.search]);
  

  return (
    <>
      {seoMeta && <Helmet onChangeClientState={handleChangeHelmetState}>
        <meta name="robots" content="index,nofollow" />
        <title>{seoMeta.title}</title>
        {seoMeta.description && <meta name="description" content={seoMeta.description} />}
        <meta property="og:title" content={seoMeta.og_title || seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta property="og:description" content={seoMeta.og_description || seoMeta.description} />}
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={seoMeta?.image} />
        <link rel="canonical" href={siteUrl} />
        <meta name="twitter:title" content={seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta name="twitter:description" content={seoMeta.description} />}
        <meta name="twitter:image" content={seoMeta?.image} />
      </Helmet>}
      <main id="allstay-main">
        <div className="allstay-contents allstay-contents-box relative">
          <div id="allstay-top-outlet-wrapper" className={`flex flex-col gap-[20px] pb-[60px] ${!!openedFilterName ? 'overflow-hidden h-[calc(100vh-50px)]' : ''}`}>
            <div className={`flex flex-col gap-[13px] sticky top-0 bg-[#FFFFFF] z-[102] drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]`}>
              <div className="grid justify-stretch gap-[10px] px-[20px] p-[20px]">
                {
                  isInputsClosed ?
                    (
                      <div className="flex justify-start items-center">
                        <div className="flex pt-[20px] pr-[20px]">
                          <BackButton />
                        </div>
                        <div className="flex justify-between w-full pt-[20px]" onClick={() => setSearchPanelVisibility(true)}>
                          <div className="flex justify-start items-center gap-[10px] text-[16px] leading-[140%] text-[#1E1E1E] font-regular">
                            {(breadCrumb || []).map((caption, index, arr) => <span key={`region-bread-crumb-${index}`} className={(index === arr.length - 1) ? 'font-bold' : ''}>{caption}</span>)}
                          </div>
                          <button onClick={() => setSearchPanelVisibility(true)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.68323 14.3665C5.54447 14.3665 3 11.822 3 8.68323C3 5.54447 5.54447 3 8.68323 3C11.822 3 14.3665 5.54447 14.3665 8.68323C14.3665 9.99649 13.921 11.2057 13.173 12.1681L17.0003 15.9953L15.9956 17L12.1684 13.1728C11.206 13.9209 9.99663 14.3665 8.68323 14.3665ZM12.9457 8.68323C12.9457 11.0373 11.0373 12.9457 8.68324 12.9457C6.32917 12.9457 4.42082 11.0373 4.42082 8.68323C4.42082 6.32916 6.32917 4.42081 8.68324 4.42081C11.0373 4.42081 12.9457 6.32916 12.9457 8.68323Z" fill="black" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : (<div className="flex justify-center items-center relative w-full pt-[20px]">
                      <button className="absolute left-[3px]" onClick={() => {
                        if (isScheduleInputOpened) toggleScheduleInputOpened();
                        else if (isRoomInfoInputOpened) toggleRoomInfoInputOpened();
                      }}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z" fill="#1E1E1E" />
                        </svg>
                        <span className="sr-only">{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택 닫기</span>
                      </button>
                      <h2>{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택</h2>
                    </div>)
                }
              </div>
              <div className="flex justify-evenly items-center gap-[10px] font-regular text-[11px] leading-[160%] text-[#1E1E1E] px-[20px]">
                <button
                  className={`grow flex justify-center items-center w-full py-[6px] rounded-full ${isScheduleInputOpened ? 'bg-[#4A90E2] text-[#FFFFFF] font-bold border-0' : 'bg-[#FFFFFF] border border-[#E0E0E0]'}`}
                  onClick={toggleScheduleInputOpened}
                >
                  {checkin} ~ {checkout}
                </button>
                <button
                  className={`grow flex justify-center items-center w-full py-[6px] rounded-full ${isRoomInfoInputOpened ? 'bg-[#4A90E2] text-[#FFFFFF] font-bold border-0' : 'bg-[#FFFFFF] border border-[#E0E0E0]'}`}
                  onClick={toggleRoomInfoInputOpened}
                >
                  객실 {room}, {!!adult && `성인 ${adult}`}{!!minor && `, 어린이 ${minor}`}
                </button>
              </div>
              <div className={`mt-[4px] w-full h-[3px] bg-[#FFFFFF] ease-out duration-2000`}>
                {!!list.length &&
                  <div
                    className={`${numOfPriceLoadedHotels === rowCount ? 'bg-[#FFFFFF]' : 'bg-[#4A90E2]'} h-full ease-out duration-500`}
                    style={{
                      width: `${numOfPriceLoadedHotels / rowCount * 100 || 2}%`,
                    }}
                  >
                    <span className="sr-only">호텔 가격 정보 로딩 {numOfPriceLoadedHotels / rowCount * 100}% 완료</span>
                  </div>
                }
              </div>
            </div>
            {isScheduleInputOpened &&
              <>
                <ScheduleInput />
                <div className="fixed left-0 bottom-0 w-full z-800">
                  <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5] relative z-[201]">
                    <button
                      onClick={() => confirmSchedule()}
                      className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                    >
                      확인하기
                    </button>
                  </div>
                </div>
              </>
            }
            {isRoomInfoInputOpened &&
              <>
                <RoomInfoInput />
                <div className="fixed left-0 bottom-0 w-full z-800">
                  <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5] relative z-[201]">
                    <button
                      onClick={() => confirmRoomInfo()}
                      className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                    >
                      확인하기
                    </button>
                  </div>
                </div>
              </>
            }
            {theme_id && <div className={`flex flex-col px-[20px] gap-[10px] ${isInputsClosed ? '' : 'hidden'}`}>
              <div className="max-w-[max-content] border border-[#4A90E2] rounded-[20px]">
                <p className="py-[2px] px-[10px] font-regular text-[#4A90E2] text-[12px] leading-[19.2px]">#올스테이추천</p>
              </div>
              <p className="font-bold text-[20px] leading-[28px] w-full" style={{ wordBreak: 'keep-all' }}>{title}</p>
            </div>}
            <div className={`flex flex-col gap-[20px] ${isInputsClosed ? '' : 'hidden'}`}>
              <div className="flex justify-between items-center gap-[10px] px-[20px]">
                <div className="flex">
                  <div className="flex items-center font-bold gap-[5px] text-[13px] leading-[160%] text-[#1E1E1E]">
                    검색된 숙소
                    <span className="text-[#4A90E2]">{filteredList.length}개</span>
                    {numOfPriceLoadedHotels === rowCount ? '' : <CircularProgress thickness={8} sx={{
                      color: '#4A90E2',
                      maxWidth: '15px',
                      maxHeight: '15px',
                    }} />}
                  </div>
                </div>
                {!theme_id &&
                  <button onClick={handleClickAllFilterToggle} ref={$filters}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_102_15426)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8332 9.16667C9.27997 9.16667 7.97489 8.10436 7.60485 6.66667H1.6665V5H7.60485C7.97489 3.56231 9.27997 2.5 10.8332 2.5C12.3864 2.5 13.6915 3.56231 14.0615 5H18.3332V6.66667H14.0615C13.6915 8.10436 12.3864 9.16667 10.8332 9.16667ZM6.6665 17.5C5.1133 17.5 3.80822 16.4377 3.43819 15H1.6665V13.3333H3.43819C3.80822 11.8956 5.1133 10.8333 6.6665 10.8333C8.2197 10.8333 9.52479 11.8956 9.89482 13.3333H18.3332V15H9.89482C9.52479 16.4377 8.2197 17.5 6.6665 17.5ZM8.33317 14.1667C8.33317 15.0871 7.58698 15.8333 6.6665 15.8333C5.74603 15.8333 4.99984 15.0871 4.99984 14.1667C4.99984 13.2462 5.74603 12.5 6.6665 12.5C7.58698 12.5 8.33317 13.2462 8.33317 14.1667ZM12.4998 5.83333C12.4998 6.75381 11.7536 7.5 10.8332 7.5C9.9127 7.5 9.1665 6.75381 9.1665 5.83333C9.1665 4.91286 9.9127 4.16667 10.8332 4.16667C11.7536 4.16667 12.4998 4.91286 12.4998 5.83333Z" fill={(!!isSortTypeOn || !!isPriceOn || !!isGradeOn || !!isCateOn) ? '#4A90E2' : '#1E1E1E'} />
                      </g>
                      <defs>
                        <clipPath id="clip0_102_15426">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                }
              </div>
            </div>
            <div className={`stay-list flex flex-col px-[20px] gap-[20px] ${isInputsClosed ? '' : 'hidden'}`}>
              {
                filteredList.map(({
                  bookmark_id,
                  hotel_id,
                  name,
                  city_name_i18n,
                  star_rating,
                  hotel_i18ns,
                  medium_data,
                  price,
                  source_table_name_key,
                  ota_num,
                  // priceInfo,
                  city_code,
                  promotions,
                }, i) => {
                  // const ottSet = new Set(priceInfo.map(({source_table_name_keys}) => source_table_name_keys));

                  if (price === Infinity || typeof price !== 'number') {
                    return <div
                      className="flex flex-col items-stretch border-b border-b-[#EEEEEE] gap-[10px] pb-[20px]"
                      key={`in-region-skeleton-${i}`}
                    >
                      <Skeleton variant="rounded" height={narrowMobileMatches ? 130 : 160} animation="wave" />
                      <div className="flex items-center justify-between">
                        <Skeleton variant="rounded" width={100} height={11} animation="wave" />
                        <Skeleton variant="rounded" width={80} height={11} animation="wave" />
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <Skeleton variant="rounded" width={230} height={15} animation="wave" />
                        <Skeleton variant="rounded" width={200} height={11} animation="wave" />
                      </div>
                      <div className="items-baseline flex gap-[5px]">
                        <Skeleton variant="rounded" width={16} height={20} animation="wave" />
                        <Skeleton variant="rounded" width={160} height={25} animation="wave" />
                      </div>
                    </div>;
                  }

                  const pathArr = window.location.pathname.split('/');
                  const trackingPromotions = promotions?.filter(promotion => isOpenPromotion({promotion}))?.map(({
                    name: promotion_name,
                    promotion_period: {
                      start_on,
                      end_on,
                    },
                    fixed_discount: promotion_fixed_discount,
                    percentage_discount: promotion_percentage_discount,
                  }) => {
                    return () => {
                      const [promotion_start_date, promotion_start_time] = start_on.split('T').map(t => t?.trim() || t);
                      const [promotion_end_date, promotion_end_time] = end_on.split('T').map(t => t?.trim() || t);

                      window?.dataLayer?.push({
                        event: 'promotion-hotel-clicks',
                        as_d1: pathArr[1],
                        as_d2: pathArr[3],
                        as_context: 'promotion-hotel-clicks',
                        hotel_name: _.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || name,
                        hotel_order: i + 1,
                        hotel_price: price,
                        page_title: window?.document?.title,
                        page_path: window?.location?.pathname,
                        page_location: window?.location?.href,
                        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                        promotion_name,
                        promotion_start_date,
                        promotion_start_time,
                        promotion_end_date,
                        promotion_end_time,
                        promotion_fixed_discount,
                        promotion_percentage_discount,
                      });
                    };
                  });

                  return <NavLink
                    onClick={() => trackingPromotions?.forEach(fn => fn())}
                    to={`/hotel/${country_code}/${normalizeCityCode(city_code)}/${hotel_id}?${sp.toString()}`}
                    state={{
                      country_code,
                      search_type,
                      region_code,
                      city_name: city_name_i18n,
                    }}
                    replace={false}
                    className="stay-list-item flex flex-col items-stretch border-b border-b-[#EEEEEE] gap-[10px] pb-[20px]"
                    key={`in-region-stay-${hotel_id}-${i}`}
                  >
                    <div
                      className="stay-list-item-img flex justify-end items-start rounded-[4px]"
                      style={{
                        backgroundImage: `url("${medium_data}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        height: `${narrowMobileMatches ? 130 : 160}px`
                      }}>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <div className="flex justify-between items-center relative font-regular text-[11px] leading-[160%] text-[#777777]">
                        <div>
                          {city_name_i18n}{star_rating === 0 ? '' : ` · ${star_rating}성급`}
                        </div>
                        <div className="flex gap-[10px]">
                        {
                            promotions?.filter(promotion => {
                              return isOpenPromotion({promotion});
                            })?.map((item, i) =>
                              <div key={`${hotel_id}--promition--${i}`} className="flex items-center gap-[2px] pl-[5px] pr-[8px] bg-[#FFE000] rounded-[20px]">
                                <PromotionIcon />
                                <span className="text-[11px] leading-[160%] font-bold text-[#000000]">{item.name}</span>
                              </div>)?.[0]
                          }
                          <FavoriteButton bookmark_id={bookmark_id} hotel_id={hotel_id} userInfo={userInfo} />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <h3 className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">{_.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || name}</h3>
                        <span className="font-regular text-[11px] leading-[160%] text-[#777777]">{_.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || name}</span>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex justify-end items-center gap-[10px]">
                          {source_table_name_key && <img alt="min-price-OTA" src={`https://static.allstay.com/channel/${source_table_name_key.toLowerCase()}.png`} style={{ height: '15px' }} />}
                          <strong className="font-bold text-[18px] leading-[140%] text-[#1E1E1E]">
                            {`${formatNum(price)}원 ~`}
                          </strong>
                        </div>
                        <div className="flex justify-between items-center">
                          {ota_num > 0 &&
                            <span className="text-[12px] leading-[160%] text-[#4A90E2]">
                              {ota_num}개 사이트 비교
                            </span>
                          }
                          {
                            parseInt(nights) > 1 &&
                            <span className="font-bold text-[12px] leading-[160%] text-[#4A90E2] ml-[auto]">{nights}박 {`${formatNum(price * nights)}원 ~`}</span>
                          }
                        </div>
                      </div>
                      <div >

                      </div>
                    </div>
                  </NavLink>;
                })
              }
              {(numOfPriceLoadedHotels !== rowCount) && !filteredList.length &&
                _.times(10, index => {
                  return <div
                    className="flex flex-col items-stretch border-b border-b-[#EEEEEE] gap-[10px] pb-[20px]"
                    key={`in-region-skeleton-${index}`}
                  >
                    <Skeleton variant="rounded" height={narrowMobileMatches ? 130 : 160} animation="wave" />
                    <div className="flex items-center justify-between">
                      <Skeleton variant="rounded" width={100} height={11} animation="wave" />
                      <Skeleton variant="rounded" width={80} height={11} animation="wave" />
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <Skeleton variant="rounded" width={230} height={15} animation="wave" />
                      <Skeleton variant="rounded" width={200} height={11} animation="wave" />
                    </div>
                    <div className="items-baseline flex gap-[5px]">
                      <Skeleton variant="rounded" width={16} height={20} animation="wave" />
                      <Skeleton variant="rounded" width={160} height={25} animation="wave" />
                    </div>
                  </div>
                })}
              {(numOfPriceLoadedHotels === rowCount && !filteredList.length && <p>열심히 찾았지만...<br />검색 결과가 없어요. 😢</p>)}
            </div>
          </div>
          {
            !!openedFilterName &&
            <div
              className="absolute fixed top-0 w-full max-h-[100vh] min-h-[100vh] h-[100vh] bg-[rgba(0,0,0,0.6)] z-800 overflow-y-auto"
              onClick={() => {
                setOpenedFilterName('');
                if (openedFilterName === 'sort-type' || openedFilterName === 'all') {
                  setIsSortTypeOn(v => v);
                  setSortingType(sortType || 'recommend');
                }
                if (openedFilterName === 'price' || openedFilterName === 'all') {
                  setIsPriceOn(v => v);
                  setSlider(priceRange || [0, 100]);
                }
                if (openedFilterName === 'grade' || openedFilterName === 'all') {
                  setIsGradeOn(v => v);
                  setStayGrade(grades || [true, true, true, true, true, true]);
                }
                if (openedFilterName === 'cate' || openedFilterName === 'all') {
                  setIsCateOn(v => v);
                  setCateBuffer(cate || [true, true, true, true, true, true].slice(0, top5Cate.length ? top5Cate.length : 6));
                  setCate(v => v ? v.slice(0, top5Cate.length ? top5Cate.length : 6) : v);
                }
              }}
            >
              <div className="flex flex-col justify-end min-h-[100vh] pt-[50px]">
                <div className="flex flex-col w-full p-[20px] pb-[30px] gap-[20px] rounded-t-[12px] bg-[#FFFFFF] font-regular text-[14px] leading-[160%] text-[#1E1E1E]"
                  onClick={(e) => e.stopPropagation()}>
                  {
                    (openedFilterName === 'sort-type' || openedFilterName === 'all') &&
                    <SortingTypeInput />
                  }
                  {openedFilterName === 'all' && <div className="h-[1px] w-full bg-[#EEEEEE]" />}
                  {
                    (openedFilterName === 'price' || openedFilterName === 'all') && numOfPriceLoadedHotels === rowCount &&
                    <PriceRangeInput
                      minPrice={minPrice}
                      maxPrice={maxPrice}
                      currency={priceCurrency}
                    />
                  }
                  {openedFilterName === 'all' && numOfPriceLoadedHotels === rowCount && <div className="h-[1px] w-full bg-[#EEEEEE]" />}
                  {
                    (openedFilterName === 'grade' || openedFilterName === 'all') &&
                    <GradeInput />
                  }
                  {openedFilterName === 'all' && <div className="h-[1px] w-full bg-[#EEEEEE]" />}
                  {
                    (openedFilterName === 'cate' || openedFilterName === 'all') &&
                    (<div className="flex flex-col gap-[20px]">
                      <label className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">숙소 분류</label>
                      <FormGroup sx={{
                        minWidth: '100%',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        justifyItems: 'start',
                        gap: '10px',
                      }}>
                        <FormControlLabel
                          control={<Checkbox
                            size="small"
                            checkedIcon={<CheckCircle />}
                            icon={<CheckCircleOutline />}
                            sx={{
                              padding: 0,
                              marginRight: '10px',
                            }}
                            name="모든 분류"
                            checked={cateBuffer.every(v => v)}
                            onChange={({ target: { checked } }) => {
                              const newCateBuffer = [...cateBuffer].slice(0, top5Cate.length ? top5Cate.length : 6);
                              setCateBuffer(newCateBuffer.map(() => checked));
                            }}
                          />}
                          sx={{
                            marginLeft: 0
                          }}
                          label="전체"
                          componentsProps={{
                            typography: {
                              color: '#1E1E1E',
                              fontWeight: 'regular',
                              fontSize: '14px',
                              lineHeight: '160%',
                            }
                          }}
                        />
                        {top5Cate.map(({ id, name }, index) => (
                          <FormControlLabel
                            key={`stay-cate-label-${id}`}
                            control={<Checkbox
                              size="small"
                              checkedIcon={<CheckCircle />}
                              icon={<CheckCircleOutline />}
                              sx={{
                                padding: 0,
                                marginRight: '10px',
                              }}
                              name={name}
                              checked={cateBuffer[index]}
                              onChange={({ target: { checked } }) => {
                                const newCateBuffer = [...cateBuffer].slice(0, top5Cate.length ? top5Cate.length : 6);
                                newCateBuffer[index] = checked;
                                setCateBuffer(newCateBuffer);
                              }}
                            />}
                            sx={{
                              marginLeft: 0
                            }}
                            label={name}
                            componentsProps={{
                              typography: {
                                color: '#1E1E1E',
                                fontWeight: 'regular',
                                fontSize: '14px',
                                lineHeight: '160%',
                              }
                            }}
                          />
                        ))}
                      </FormGroup>
                    </div>)
                  }
                </div>
                <button className="sticky bottom-0 flex justify-center max-w-[480px] items-center bg-[#FFFFFF] w-full min-h-[50px] border-t border-t-[#EEEEEE] font-bold text-[14px] leading-[160%] text-[#4A90E2]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenedFilterName('');
                    if (openedFilterName === 'sort-type' || openedFilterName === 'all') {
                      setIsSortTypeOn(true);
                      setSortType(getSortingType());
                    }
                    if (openedFilterName === 'price' || openedFilterName === 'all') {
                      setIsPriceOn(numOfPriceLoadedHotels === rowCount);
                      setPriceRange(getSlider());
                    }
                    if (openedFilterName === 'grade' || openedFilterName === 'all') {
                      const rawGradeVal = getStayGrade();
                      const isAllFalse = rawGradeVal.every(v => !v);
                      if (isAllFalse) {
                        setStayGrade(isGradeOn ? (grades || rawGradeVal) : initialGrade);
                        return;
                      }
                      setIsGradeOn(true);
                      setGrades(getStayGrade());
                    }
                    if (openedFilterName === 'cate' || openedFilterName === 'all') {
                      const rawCateVal = cateBuffer.slice(0, top5Cate.length ? top5Cate.length : 6);
                      const isAllFalse = rawCateVal.every(v => !v);
                      if (isAllFalse) {
                        setCateBuffer(
                          isCateOn ?
                            (cate || rawCateVal) :
                            [true, true, true, true, true, true].slice(0, top5Cate.length ? top5Cate.length : 6) // cateBuffer 초기값
                        );
                        return;
                      }
                      setIsCateOn(true);
                      setCate(rawCateVal);
                    }
                  }}
                >
                  적용하기
                </button>
              </div>
            </div>
          }
          {theme_id && isInputsClosed && <div className={`sticky bottom-0 gnb z-[102] pb-[56px] bg-[#ffffff] ${isHide ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
            <div className="relative flex w-full min-w-[350px] h-[80px] pt-[14px] pb-[15px] px-[20px] justify-around items-center min-w-[350px] grow border-t-[1px] border-t-[#F5F5F5]">
              <a href={`/search/${country_code}/city/${region_code}`} className="flex w-full h-full items-center justify-center bg-[#EA4B5B] rounded-[4px] font-bold text-[14px] text-[#ffffff] leading-[22.4px]">
                더 많은 숙소 검색하기
              </a>
            </div>
          </div>}
        </div>
        {!theme_id &&
          <div className={`${isFilterScrolledOut && isInputsClosed ? '' : 'hidden'} flex w-full justify-center sticky bottom-[80px]`}>
            <div className="flex justify-center items-center rounded-full bg-[#FFFFFF] drop-shadow-[0_7px_5px_rgba(0,0,0,0.1)]">
              <button
                className={`${(!!isSortTypeOn || !!isPriceOn || !!isGradeOn || !!isCateOn) ? `${filterToggleActiveClass}` : ` border border-[1px] ${filterToggleInactiveClass}`} border-[#1E1E1E] flex justify-center items-center gap-[10px] h-[30px] font-regular text-[13px]`}
                onClick={handleClickAllFilterToggle}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_102_15426)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.8332 9.16667C9.27997 9.16667 7.97489 8.10436 7.60485 6.66667H1.6665V5H7.60485C7.97489 3.56231 9.27997 2.5 10.8332 2.5C12.3864 2.5 13.6915 3.56231 14.0615 5H18.3332V6.66667H14.0615C13.6915 8.10436 12.3864 9.16667 10.8332 9.16667ZM6.6665 17.5C5.1133 17.5 3.80822 16.4377 3.43819 15H1.6665V13.3333H3.43819C3.80822 11.8956 5.1133 10.8333 6.6665 10.8333C8.2197 10.8333 9.52479 11.8956 9.89482 13.3333H18.3332V15H9.89482C9.52479 16.4377 8.2197 17.5 6.6665 17.5ZM8.33317 14.1667C8.33317 15.0871 7.58698 15.8333 6.6665 15.8333C5.74603 15.8333 4.99984 15.0871 4.99984 14.1667C4.99984 13.2462 5.74603 12.5 6.6665 12.5C7.58698 12.5 8.33317 13.2462 8.33317 14.1667ZM12.4998 5.83333C12.4998 6.75381 11.7536 7.5 10.8332 7.5C9.9127 7.5 9.1665 6.75381 9.1665 5.83333C9.1665 4.91286 9.9127 4.16667 10.8332 4.16667C11.7536 4.16667 12.4998 4.91286 12.4998 5.83333Z" fill={!!isSortTypeOn || !!isPriceOn || !!isGradeOn || !!isCateOn ? '#FFFFFF' : '#1E1E1E'} />
                  </g>
                  <defs>
                    <clipPath id="clip0_102_15426">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>필터</span>
              </button>
              {
                (!!isSortTypeOn || !!isPriceOn || !!isGradeOn || !!isCateOn) &&
                <button
                  className="flex justify-center items-center h-[30px] pl-[2px] pr-[14px] bg-[#4A90E2] text-[#FFFFFF] rounded-r-full"
                  onClick={() => {
                    handleClickCancelSortTypeToggle();
                    handleClickCancelPriceToggle();
                    handleClickCancelGradeToggle();
                    handleClickCancelCateToggle();
                  }}
                >
                  <span className="sr-only">필터링 적용 해제</span>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_660_6732)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.6668 4.77333L11.7268 3.83333L8.00016 7.56L4.2735 3.83333L3.3335 4.77333L7.06016 8.5L3.3335 12.2267L4.2735 13.1667L8.00016 9.43999L11.7268 13.1667L12.6668 12.2267L8.94016 8.5L12.6668 4.77333Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_660_6732">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              }
            </div>
          </div>
        }

      </main>
    </>
  );
}

export default RegionSearchResult;
