
import { BlogPost } from '@page/AllTrip';
import Contents from '@page/contents/Contents';
import Magazine from '@page/contents/Magazine';
// import ContentsMore from '@page/contents/ContentsMore';
import { blogPost } from '@service/blog';
import {
  curation, getPastTagList, getRegionList, getTagList,
  getTaggedHotelList,
  getTaggedPostList,
  // curationPaging,
} from '@service/curation';
import { resetModal, setModalIsOpened } from '@store/modal';
import { Route } from 'react-router-dom';

const invalidHhotelNameRe = /^[`~!@#$%^&*()_+\-=[\]{},./<>?\s]+$/;


const getCriticalVars = (data, location_type) => {
  const country_code = data?.curation?.city?.country_code;
  const city_code = data?.curation?.city?.city_code;
  const region_id = data?.curation?.city?.region_id;
  const tag_code = data?.curation?.tag?.tag_code;
  const result = {
    canonical: `${window.location.protocol}//${window.location.host}/alltrip/${location_type}-curation/${country_code}/city/${city_code}/${tag_code}`,
    country_code,
    city_code,
    tag_code,
  };
  result.tag_id = result.tag_code.split('-').pop();
  return result;
}

const curationLoader = async ({ params: _params, request }, location_type) => {
  const {
    tag_id: _tag_id,
    tag_code: _tag_code,
    ...params
  } = _params;
  let tag_id = _tag_id || _tag_code?.split('-')?.pop();
  const reqUrl = new URL(request.url);
  let isCurationHomePage = false;
  if (/^\/+alltrip\/+(domestic|overseas)-curation\/*$/.test(reqUrl.pathname)) {
    isCurationHomePage = true;
  }
  const reqParams = reqUrl?.searchParams;
  let region_id = reqParams?.get('region_id');
  let isArchive;

  const payload = { ...params, tag_id, location_type, region_id, searchParams: reqParams };

  const { isOK: isCuratioOK, data, } = await curation(payload);
  const isCurationNotEmpty = data?.curation?.posts?.length || data?.curation?.hotels?.length;
  
  if (isCuratioOK && isCurationNotEmpty) {
    // 큐레이션 데이터 정상 AND 큐레이션 데이터 비어있지 않음
    const criticalVars = getCriticalVars(data, location_type);
    if (isCurationHomePage) {
      data.seo_meta.url = criticalVars.canonical = reqUrl;
    } else {
      data.seo_meta.url = criticalVars.canonical;
    }
    data.curation.hotels = data.curation.hotels.filter(({ hotel }) => !invalidHhotelNameRe.test(hotel));
    return { ...data, ...criticalVars, location_type, isArchive };
  }

  if (!tag_id) return '더보기용 태그 아이디가 제시되지 않음';
  const tagsRes = await getPastTagList({ location_type });
  const _tag = tagsRes?.data?.find(t => `${t.tag_id}` === `${tag_id}`);
  if (!tagsRes?.isOK || !_tag || !_tag?.tag_id) return '제시된 아이디의 태그 더보기 리스트가 비어있음';

  // tagged post list 요청 AND tagged hotel list 요청
  isArchive = true;
  payload.tag_id = tag_id = tag_id || _tag.tag_id;
  
  return await Promise.all([
    getTaggedPostList(payload),
    getTaggedHotelList(payload),
    getRegionList({ location_type, tag_id }),
  ]).then(([
    postsRes,
    hotelsRes,
    regionsRes,
  ]) => {
    if ((!postsRes?.isOK && !hotelsRes?.isOK) || !regionsRes?.isOK) return `더보기 포스트, 숙소, 지역 불러오기 실패, postsRes ${postsRes?.isOK}, hotelsRes ${hotelsRes?.isOK}, tagsRes ${tagsRes?.isOK}, regionsRes ${regionsRes?.isOK}`;
    if (!postsRes?.data?.length && !hotelsRes?.data?.length) return `더보기 포스트와 숙소 리스트가 모두 비어있음, postsRes ${postsRes?.data?.length}, hotelsRes ${hotelsRes?.data?.length}`;
    if (!region_id) region_id = _tag?.region_id;
    const _region = regionsRes?.data?.find(r => `${r.region_id}` === `${region_id}`) || regionsRes?.data?.[0];
    const titleBase = _tag?.tag;
    const regionBase = _region?.name || _region?.city;
    const canonical = `${window.location.protocol}://${window.location.host}/alltrip/${location_type}-curation/${_region?.country_code}/city/${_region?.city_code}/${_tag?.tag_code}`;
    const _data = {
      tags: tagsRes?.data,
      cities: regionsRes?.data,
      curation: {
        tag: _tag,
        city: _region,
        posts: postsRes?.data,
        hotels: hotelsRes?.data,
      },
      seo_meta: {
        title: `${titleBase} 여행 정보와 숙소 예약을 한번에 | 올스테이`,
        description: `${titleBase} ${regionBase}에 대한 여행 정보를 얻고 최저가 숙소 예약까지 한번에 끝내세요. 기분 좋은 여행에만 집중하세요.`,
        og_title: '세부 0.5박숙소 즐기기',
        og_description: '여행 정보와 숙소 예약을 한번에',
        image: postsRes?.data?.[0]?.image,
        url: canonical,
      },
    };
    const criticalVars = getCriticalVars(_data, location_type);
    _data.curation.hotels = _data.curation.hotels.filter(({ hotel }) => !invalidHhotelNameRe.test(hotel));
    return { ..._data, ...criticalVars, location_type, isArchive };
  });
};
const domesticLoader = async (loaderContext) => {
  resetModal();
  setModalIsOpened(true);
  const data = await curationLoader(loaderContext, 'domestic');
  setModalIsOpened(false);
  resetModal();
  if (typeof data !== 'object') throw new Error(data);
  return data;
};
const overseasLoader = async (loaderContext) => {
  resetModal();
  setModalIsOpened(true);
  const data = await curationLoader(loaderContext, 'overseas');
  setModalIsOpened(false);
  resetModal();
  if (typeof data !== 'object') throw new Error(data);
  return data;
};

// const moreLoader = ({ request }) => {
//   const searchParam = (new URL(request.url)).searchParams;
//   const location_type = searchParam.get('location_type');
//   const tag_id = searchParam.get('tag_id');
//   const region_id = searchParam.get('region_id');
//   resetModal();
//   setModalIsOpened(true);
//   return curationPaging(location_type, tag_id, region_id).then(({ isOK, data }) => {
//     if (!isOK) throw new Error(`${location_type} 큐레이션 더보기 실패. tag_id: ${tag_id}, region_id: ${region_id}`);
//     return { ...data, location_type, region_id, tag_id };
//   }).finally(() => setModalIsOpened(false));
// };

const blogPostLoader = ({ params: { slug, tag_name }}) => {
  resetModal();
  setModalIsOpened(true);
  return blogPost(slug).then((data) => {
    if (!data.id || !data.title || !data.content) throw new Error('블로그 포스트 상세 에러');
    data.seo_meta.url = data?.seo_meta?.url || `${window.location.protocol}//${window.location.host}/alltrip/post/${slug}`;
    return { ...data, slug };
  }).finally(() => setModalIsOpened(false));  
};

const magazineLoader = async ({ params, request }, location_type) => {
  try {
    resetModal();
    setModalIsOpened(true);
    const tags = await getTagList({ location_type }).then(({
      data, // tags
      isOK,
    }) => {
      if (isOK && data?.length) {
        return data;
      } throw new Error('비정상 태그 리스트 응답');
    }).catch((err) => {
      console.err(err);
      return [];
    });
    if (!tags.length) throw new Error('비정상 태그 리스트 응답');
    const tag_id = (params?.tag_id || `${tags?.[0]?.tag_id}`)?.split('-')?.pop();

    const regions = await getRegionList({ location_type, tag_id }).then(({
      data,
      isOK,
    }) => {
      if (isOK && data?.length) {
        return data;
      } throw new Error('비정상 지역 리스트 응답');
    }).catch((err) => {
      console.err(err);
      return [];
    });
    if (!regions.length) throw new Error('비정상 지역 리스트 응답');
    const { searchParams } = new URL(request.url);
    const region_id = (searchParams.get('region_id') || `${regions?.[0]?.region_id}`)?.split('-')?.pop();
    const country_code = params?.country_code || regions?.[0]?.country_code;

    const posts = await getTaggedPostList({
      location_type,
      tag_id,
      region_id,
      country_code,
    }).then(({
      data,
      isOK,
    }) => {
      if (isOK && data?.length) {
        return data;
      } throw new Error('비정상 태그된-포스트 리스트 응답');
    }).catch((err) => {
      console.err(err);
      return [];
    });
    if (!regions.length) throw new Error('비정상 태그된-포스트 리스트 응답');
    
    const activeTag = { ...(tags.find(t => t.tag_id === tag_id) || {}) };
    const activeRegion = { ...(regions.find(r => r.region_id === region_id) || {}) };

    const loaded = {
      tags: [ ...(tags.map((t, i) => ({ ...t, active: t.tag_id === tag_id, originIndex: i })) || []) ],
      regions: [ ...(regions.map((r, i) => ({ ...r, active: r.region_id === region_id, originIndex: i })) || []) ],
      location_type,
      activeTag,
      activeRegion,
      posts,
      seo_meta: {
        title: `매거진 - ${activeTag?.tag} - ${activeRegion?.city} | 올스테이`,
        description: `${activeRegion?.city}의 ${activeTag?.tag} 관련 포스트를 전해 드려요!`,
        image: posts?.[0]?.image,
        canonical: `${window.location.protocol}://${window.location.host}/alltrip/magazine/${location_type}/${activeTag?.tag_code}/${activeRegion?.country_code}/${activeRegion?.city_code}?region_id=${activeRegion?.region_id}`,
      }
    };

    return loaded;
  } catch (err) {
    throw new Error(err);
  } finally {
    setModalIsOpened(false);
  }
};
const domesticMagazineLoader = (loaderContext) => magazineLoader(loaderContext, 'domestic');
const overseasMagazineLoader = (loaderContext) => magazineLoader(loaderContext, 'overseas');


const ContentsRouteGroup = (
  <Route path="/alltrip">
    <Route path=":tag_name/post/:slug" element={<BlogPost />} loader={blogPostLoader} />
    <Route path="post/:slug" element={<BlogPost />} loader={blogPostLoader} />
    <Route path="domestic-curation">
      <Route index loader={domesticLoader} element={<Contents />}/>
      <Route path=":tag_code" loader={domesticLoader} element={<Contents />}/>
      <Route path=":country_code/city/:city_code/:tag_code" loader={domesticLoader} element={<Contents />} />
      {/* <Route path=":country_code/city/:city_code/:tag_code" loader={moreLoader} element={<ContentsMore />} /> */}
    </Route>
    <Route path="overseas-curation">
      <Route index loader={overseasLoader} element={<Contents locationType="overseas" />}/>
      <Route path=":tag_code" loader={overseasLoader} element={<Contents locationType="overseas"/>}/>
      <Route path=":country_code/city/:city_code/:tag_code" loader={overseasLoader} element={<Contents locationType="overseas"/>} />
      {/* <Route path=":country_code/city/:city_code/:tag_code" loader={moreLoader} element={<ContentsMore />} /> */}
    </Route>
    <Route path="magazine">
      <Route path="domestic">
        <Route index element={<Magazine />} key="magazine-domestic" loader={domesticMagazineLoader} />
        <Route path=":tag_id">
          <Route index element={<Magazine />} key="magazine-domestic-tag" loader={domesticMagazineLoader} />
          <Route path=":country_code/:city_code" element={<Magazine />} key="magazine-domestic-tag-region" loader={domesticMagazineLoader} />
        </Route>
      </Route>
      <Route path="overseas">
        <Route index element={<Magazine />} key="magazine-overseas" loader={overseasMagazineLoader} />
        <Route path=":tag_id">
          <Route index element={<Magazine />} key="magazine-overseas-tag" loader={overseasMagazineLoader} />
          <Route path=":country_code/:city_code" element={<Magazine />} key="magazine-overseas-tag-region" loader={overseasMagazineLoader} />
        </Route>
      </Route>
      {/* <Route path="overseas" element={<Magazine />} key="magazine-overseas" loader={overseasMagazineLoader}></Route> */}
    </Route>
  </Route>
);

export default ContentsRouteGroup;
